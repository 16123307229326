import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { merge } from 'lodash';

import { NewOnboardingStore } from 'types/new-onboarding';

const initialState: NewOnboardingStore = {
  hidden: false,
  transitioning: false,
};

export const newOnboarding = createSlice({
  name: 'newOnboarding',
  initialState,
  reducers: {
    updateByPayload: (state, action: PayloadAction<NewOnboardingStore>) => {
      state = merge(state, action.payload);
    },
  },
});

export const { updateByPayload } = newOnboarding.actions;

export default newOnboarding.reducer;
