import { createApi } from '@reduxjs/toolkit/query/react';

import { APICall } from 'types';
import {
  AllJobsQuery,
  AllJobsResponse,
  CompletedJobsQuery,
  CompletedJobsResponse,
  CompleteJobData,
  JobDetailsResponse,
  JobQuery,
  ScheduledJobsQuery,
  ScheduledJobsResponse,
  UnscheduledJobsQuery,
  UnscheduledJobsResponse,
} from 'types/jobs';
import { authQuery } from 'utils/api';

export const contractorApi = createApi({
  reducerPath: 'contractorApi',
  baseQuery: authQuery,
  tagTypes: [
    'Jobs',
    'Job',
    'ScheduledJobs',
    'UnscheduledJobs',
    'CompletedJobs',
  ],
  endpoints: (builder) => ({
    getJobs: builder.query<AllJobsResponse, AllJobsQuery>({
      query: ({ contractorId }) => {
        return `contractor/${contractorId}/jobs`;
      },
      providesTags: ['Jobs'],
    }),
    getScheduledJobs: builder.query<ScheduledJobsResponse, ScheduledJobsQuery>({
      query: ({ contractorId }) => {
        return `contractor/${contractorId}/scheduled_jobs`;
      },
      providesTags: ['ScheduledJobs'],
    }),
    getUnscheduledJobs: builder.query<
      UnscheduledJobsResponse,
      UnscheduledJobsQuery
    >({
      query: ({ contractorId }) => {
        return `contractor/${contractorId}/unscheduled_jobs`;
      },
      providesTags: ['UnscheduledJobs'],
    }),
    getCompletedJobs: builder.query<CompletedJobsResponse, CompletedJobsQuery>({
      query: ({ contractorId }) => {
        return `contractor/${contractorId}/completed_jobs`;
      },
      providesTags: ['CompletedJobs'],
    }),
    getJob: builder.query<JobDetailsResponse, JobQuery>({
      query: ({ contractorId, quoteId }) =>
        `contractor/${contractorId}/quote/${quoteId}`,
      providesTags: ['Job'],
    }),
    completeJob: builder.mutation<APICall, CompleteJobData>({
      query: ({
        additionalAmount,
        contractorId,
        quoteId,
        notes,
        reasoning,
      }) => {
        const body = {
          additional_payment_amount: additionalAmount,
          notes,
          reasoning,
        };

        return {
          url: `contractor/${contractorId}/quote/${quoteId}/complete_job`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Jobs', 'Job'],
    }),
  }),
});

export const {
  useCompleteJobMutation,
  useGetJobsQuery,
  useGetJobQuery,
  useGetScheduledJobsQuery,
  useGetCompletedJobsQuery,
  useGetUnscheduledJobsQuery,
  useLazyGetUnscheduledJobsQuery,
} = contractorApi;
