import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { merge } from 'lodash';

interface Form {
  additionalAmount?: string;
  quoteId?: string;
  notes?: string;
  reasoning?: string;
}

const initialState: Form = {
  additionalAmount: '',
  quoteId: '',
  notes: '',
  reasoning: '',
};

export const completeJob = createSlice({
  name: 'completeJob',
  initialState,
  reducers: {
    updateByPayload: (state, action: PayloadAction<Form>) => {
      if (action.payload.quoteId && action.payload.quoteId !== state.quoteId) {
        state = merge(state, initialState, action.payload);
      } else {
        state = merge(state, action.payload);
      }
    },
  },
});

export const { updateByPayload } = completeJob.actions;

export default completeJob.reducer;
