import { createApi } from '@reduxjs/toolkit/query/react';

import { InstallPartnerTeam } from 'types/monday';
import { authQuery } from 'utils/api';

export const mondayApi = createApi({
  reducerPath: 'mondayApi',
  baseQuery: authQuery,
  endpoints: (builder) => ({
    getInstallPartnerTeam: builder.query<InstallPartnerTeam[], string>({
      query: (installPartnerId) =>
        `monday/monday-install-partner-team?install_partner_id=${installPartnerId}`,
    }),
  }),
});

export const { useGetInstallPartnerTeamQuery } = mondayApi;
