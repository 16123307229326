import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface Cards {
  expandedCards: Array<string>;
}

const initialState: Cards = {
  expandedCards: [],
};

export const cards = createSlice({
  name: 'cards',
  initialState,
  reducers: {
    collapseCard: (state, action: PayloadAction<string>) => {
      const index = state.expandedCards.indexOf(action.payload);
      if (index > -1) {
        state.expandedCards.splice(index, 1);
      }
    },
    expandCard: (state, action: PayloadAction<string>) => {
      const index = state.expandedCards.indexOf(action.payload);
      if (index === -1) {
        state.expandedCards.push(action.payload);
      }
    },
  },
});

export const { collapseCard, expandCard } = cards.actions;

export default cards.reducer;
