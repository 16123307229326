import React, { FC, ReactNode } from 'react';

interface LayoutWrapper {
  getLayout: (children: ReactNode) => ReactNode;
  children: React.ReactElement;
}
const LayoutWrapper: FC<LayoutWrapper> = ({ getLayout, children }) => {
  return <>{getLayout(children)}</>;
};

export default LayoutWrapper;
