import { createApi } from '@reduxjs/toolkit/query/react';

import APICall from 'types/APICall';
import { JobDetailsResponse } from 'types/jobs';
import { authQuery } from 'utils/api';

interface InstallationQuery {
  quoteId?: string;
  contractorId?: string;
}

interface AcceptJobData {
  confirmedDate: string;
  contractorId: string;
  quoteId: string;
}

export const schedulerApi = createApi({
  reducerPath: 'schedulerApi',
  baseQuery: authQuery,
  endpoints: (builder) => ({
    getInstallation: builder.query<JobDetailsResponse, InstallationQuery>({
      query: ({ quoteId, contractorId }) =>
        `scheduler/quote/${quoteId}/contractor/${contractorId}/installation`,
    }),
    acceptJob: builder.mutation<APICall, AcceptJobData>({
      query: ({ confirmedDate, contractorId, quoteId }) => ({
        url: `scheduler/quote/${quoteId}/contractor`,
        method: 'POST',
        body: {
          confirmed_date: confirmedDate,
          contractor: contractorId,
          quote_id: quoteId,
          status: 'IS_CONFIRMED',
        },
      }),
    }),
  }),
});

export const { useAcceptJobMutation, useGetInstallationQuery } = schedulerApi;
