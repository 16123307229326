import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { merge } from 'lodash';

import { GeneralStore } from 'types/general';

const initialState: GeneralStore = {
  internal: false,
};

export const general = createSlice({
  name: 'general',
  initialState,
  reducers: {
    updateByPayload: (state, action: PayloadAction<GeneralStore>) => {
      state = merge(state, action.payload);
    },
  },
});

export const { updateByPayload } = general.actions;

export default general.reducer;
