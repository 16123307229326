import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { merge } from 'lodash';

import { RebateEligibility } from 'types/rebate-eligibility';

const initialState: RebateEligibility = {
  address: '',
  addressData: {
    city: '',
    county: '',
    state: '',
    street_address: '',
    zip_code: '',
  },
  addressSelected: false,
  barrierRemediation: '',
  contactInfo: {
    email: '',
    name: '',
    phone: '',
  },
  fuelAssistance: '',
  hea: '',
  homeDetails: {
    area_in_sqft: '',
    basement_height: '',
    bath_count: '',
    bath_count_total: '',
    bath_partial_count: 0,
    cooling_system: '',
    fuel_type: '',
    fully_finished_basement: '',
    heating_system: '',
    home_style: '',
    home_type: '',
    id: '',
    municipal_utilities: false,
    units_count: 0,
    year_built: 0,
    zones: '',
  },
  homeOccupancy: '',
  householdIncome: '',
  householdMembers: '',
  insulationMeasure: '',
  insulationRequirement: '',
  utilityProvider: '',
  whatIsAHea: '',
};

export const rebateEligibility = createSlice({
  name: 'rebateEligibility',
  initialState,
  reducers: {
    updateByPayload: (state, action: PayloadAction<RebateEligibility>) => {
      state = merge(state, action.payload);
    },
  },
});

export const { updateByPayload } = rebateEligibility.actions;

export default rebateEligibility.reducer;
