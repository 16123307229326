import { InlineWidget } from 'react-calendly';
import styles from './styles.module.css';

const LeadOwnerCalendly = () => {
  const calendlyURL = `https://calendly.com/zoe-tetra/30min?hide_gdpr_banner=1`;

  return (
    <div className={styles.calendly_container}>
      <InlineWidget
        styles={{ height: '100%', overflow: 'hidden' }}
        url={calendlyURL}
      />
    </div>
  );
};

export default LeadOwnerCalendly;
