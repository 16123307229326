import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { merge } from 'lodash';

type ErrorTypes =
  | 'home_size'
  | 'commercial_multifam'
  | 'area_code_not_served'
  | 'address_not_found'
  | '';
export interface Error {
  message?: string;
  errorType?: ErrorTypes;
}

export const initialState: Error = {
  errorType: '',
  message: '',
};

export const error = createSlice({
  name: 'error',
  initialState,
  reducers: {
    updateByPayload: (state, action: PayloadAction<Error>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state = merge(state, action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateByPayload } = error.actions;

export default error.reducer;
