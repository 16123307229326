import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type AuthState = {
  access: string | null;
  contractorId: string;
  customerId: string;
  refresh: string | null;
};

type AuthStatePayload = {
  access?: string | null;
  contractorId?: string | null;
  customerId?: string;
  refresh?: string | null;
};

const slice = createSlice({
  name: 'auth',
  initialState: {
    access: null,
    contractorId: '',
    customerId: '',
    refresh: null,
  } as AuthState,
  reducers: {
    setTokens: (
      state,
      {
        payload: { access, contractorId, customerId, refresh },
      }: PayloadAction<AuthStatePayload>
    ) => {
      state.access = access !== undefined ? access : state.access;
      state.contractorId = contractorId ?? state.contractorId;
      state.customerId = customerId ?? state.customerId;
      state.refresh = refresh !== undefined ? refresh : state.refresh;
    },
  },
});

export const { setTokens } = slice.actions;

export default slice.reducer;
