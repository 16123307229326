import { ReactNode, useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import { RootState } from 'redux/store';
import {
  CONTRACTOR_DASHBOARD_URL,
  HOMEOWNER_DASHBOARD_URL,
} from 'utils/constants';

interface Props {
  children: ReactNode;
}

const PRIVATE_PATHS = [CONTRACTOR_DASHBOARD_URL, HOMEOWNER_DASHBOARD_URL];

const RouteGuard = ({ children }: Props) => {
  const router = useRouter();
  const auth = useSelector((state: RootState) => state.auth);

  const isLoginPage = useCallback((url: string) => {
    const path = url.split('?')[0];
    return path.endsWith('/login');
  }, []);

  const isPrivatePath = useCallback(
    (url: string) => {
      const path = url.split('?')[0];
      return PRIVATE_PATHS.some(
        (privatePath) => path.startsWith(privatePath) && !isLoginPage(url)
      );
    },
    [isLoginPage]
  );

  useEffect(() => {
    const authCheck = (url: string) => {
      if (!auth.access && isPrivatePath(url)) {
        const mainPath = PRIVATE_PATHS.find((privatePath) =>
          url.startsWith(privatePath)
        );
        if (mainPath) {
          router.push(`${mainPath}login`);
        } else {
          router.push('/');
        }
      } else if (auth.access) {
        if (isLoginPage(url)) {
          if (auth.contractorId) {
            router.push(CONTRACTOR_DASHBOARD_URL);
          } else if (auth.customerId) {
            router.push(HOMEOWNER_DASHBOARD_URL);
          }
        } else if (
          !auth.contractorId &&
          url.startsWith(CONTRACTOR_DASHBOARD_URL)
        ) {
          router.push(HOMEOWNER_DASHBOARD_URL);
        } else if (
          !auth.customerId &&
          url.startsWith(HOMEOWNER_DASHBOARD_URL)
        ) {
          router.push(CONTRACTOR_DASHBOARD_URL);
        }
      }
    };

    authCheck(router.asPath);

    router.events.on('routeChangeComplete', authCheck);

    return () => {
      router.events.off('routeChangeComplete', authCheck);
    };
  }, [auth, isLoginPage, isPrivatePath, router]);

  if (!auth.access && isPrivatePath(router.asPath)) {
    return null;
  }

  return <>{children}</>;
};

export default RouteGuard;
