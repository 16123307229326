import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_Endpoint } from '../utils/api';

interface Authentication {
  email: string;
  password: string;
}

interface AuthenticationResponse {
  message: string;
  refresh: string;
  access: string;
  status: string;
}

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_Endpoint }),
  endpoints: (builder) => ({
    login: builder.mutation<AuthenticationResponse, Authentication>({
      query: ({ email, password }) => ({
        url: 'user/login',
        method: 'POST',
        body: {
          email: email.toLowerCase(),
          password,
        },
      }),
    }),
  }),
});

export const { useLoginMutation } = userApi;
