import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import type { RootState } from 'redux/store';
import { setTokens } from 'redux/features/auth';
import { isRefreshResult } from 'types/typeGuards';

export const API_Endpoint = process.env.NEXT_PUBLIC_API_URL!;

const authorizationHeaderQuery = fetchBaseQuery({
  baseUrl: API_Endpoint,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.access;

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

export const authQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await authorizationHeaderQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    const token = (api.getState() as RootState).auth.refresh;
    const refreshResult = await authorizationHeaderQuery(
      {
        url: '/user/token/refresh',
        method: 'POST',
        body: {
          refresh: token,
        },
      },
      api,
      extraOptions
    );
    if (refreshResult.data) {
      if (isRefreshResult(refreshResult)) {
        api.dispatch(
          setTokens({ access: refreshResult.data.access, refresh: token })
        );
      }
      result = await authorizationHeaderQuery(args, api, extraOptions);
    } else {
      api.dispatch(setTokens({ access: null, refresh: null }));
    }
  }
  return result;
};
