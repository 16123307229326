import TagManager from 'react-gtm-module';

const initializeGtm = () => {
  TagManager.initialize({
    gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
  });
};

const sendGtmDataLayerEvent = (eventName: string, dimensions: {}) => {
  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      ecomm: true,
      ...dimensions,
    },
  });
};

export { initializeGtm, sendGtmDataLayerEvent };
