import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

interface ErrorWithDetail {
  status: number;
  data: {
    detail: string;
  };
}

interface ErrorWithData {
  status: number;
  data: {
    data: string;
  };
}

interface ErrorWithMessage {
  status: number;
  message: string;
}

interface RefreshResult {
  data: {
    access: string;
  };
}

export function isFetchBaseQueryError(
  error: unknown
): error is FetchBaseQueryError {
  return typeof error === 'object' && error !== null && 'status' in error;
}

export function isErrorWithDetail(error: unknown): error is ErrorWithDetail {
  return (
    isFetchBaseQueryError(error) &&
    typeof error.data === 'object' &&
    error.data !== null &&
    'detail' in error.data
  );
}

export function isErrorWithData(error: unknown): error is ErrorWithData {
  return (
    isFetchBaseQueryError(error) &&
    typeof error.data === 'object' &&
    error.data !== null &&
    'data' in error.data
  );
}

export function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    isFetchBaseQueryError(error) &&
    'message' in error &&
    typeof error.message === 'string'
  );
}

export function isRefreshResult(result: unknown): result is RefreshResult {
  return (
    typeof result === 'object' &&
    result != null &&
    'data' in result &&
    typeof result.data === 'object' &&
    result.data != null &&
    'access' in result.data
  );
}

interface ValidationErrorResponse {
  status: number;
  data: {
    errors: {
      [index: string]: Array<string>;
    };
  };
}

export function isValidationErrorResponse(
  response: unknown
): response is ValidationErrorResponse {
  return (
    isFetchBaseQueryError(response) &&
    typeof response.data === 'object' &&
    response.data !== null &&
    'errors' in response.data &&
    typeof response.data.errors === 'object' &&
    response.data.errors !== null &&
    Object.keys(response.data.errors).length > 0
  );
}
